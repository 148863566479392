import * as React from "react"
import { createIcon } from "@chakra-ui/react"

export const FeedbackThumbsUpIcon = createIcon({
  displayName: "FeedbackThumbsUpIcon",
  viewBox: "0 0 25 25",
  defaultProps: {
    width: 6,
    height: 6,
  },
  path: [
    <path
      d="M5.85334 8.04297H3.27591C2.92157 8.04297 2.63153 8.33154 2.63153 8.68731L2.63153 20.2858C2.63153 20.6414 2.9203 20.9301 3.27591 20.9301H5.85334C6.20894 20.9301 6.49771 20.6414 6.49771 20.2858L6.49771 8.68731C6.49771 8.33154 6.20763 8.04297 5.85334 8.04297Z"
      fill="currentColor"
    />,
    <path
      d="M20.7596 8.04527C20.6558 8.02769 15.7969 8.04527 15.7969 8.04527L16.4756 6.19371C16.9439 4.9149 16.6407 2.96231 15.3413 2.26812C14.9183 2.04208 14.3272 1.92855 13.8506 2.04851C13.5773 2.11728 13.3371 2.29823 13.1939 2.54066C13.0293 2.81948 13.0463 3.14495 12.9877 3.45411C12.839 4.23826 12.4684 4.98383 11.8947 5.54344C10.8942 6.51914 7.7865 9.33395 7.7865 9.33395L7.7865 19.6437L18.5258 19.6437C19.975 19.6446 20.9246 18.0263 20.2099 16.7623C21.0619 16.2166 21.3533 15.0673 20.8543 14.1848C21.7063 13.6392 21.9976 12.4899 21.4987 11.6074C22.9687 10.6659 22.4772 8.33577 20.7596 8.04527Z"
      fill="currentColor"
    />,
  ],
})
