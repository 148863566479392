/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Prism from "prism-react-renderer";
(typeof global !== "undefined" ? global : window).Prism = Prism

// FIXME:
//
// Error in function eval in ./node_modules/prismjs/components/prism-applescript.js:1
// Cannot read properties of undefined (reading 'languages')
//
// ./node_modules/prismjs/components/prism-applescript.js:1
// Open in Editor
// > 1 | Prism.languages.applescript = {
//   2 | 	'comment': [
//   3 | 		// Allow one level of nesting
//   4 | 		/\(\*(?:\(\*(?:[^*]|\*(?!\)))*\*\)|(?!\(\*)[\s\S])*?\*\)/,
//
await import("prismjs/components/prism-applescript")

// FormatJS Polyfill imports - Used for intl number formatting
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-numberformat/polyfill"
import "@formatjs/intl-numberformat/locale-data/en"

// Default languages included:
// https://github.com/FormidableLabs/prism-react-renderer/blob/master/src/vendor/prism/includeLangs.js
require("prismjs/components/prism-solidity")
