import { useColorMode } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { cloneDeep } from "lodash"
import { useRef, useState } from "react"
import { IItem, ISections } from "./types"

export const useNav = ({ path }: { path: string }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { colorMode, toggleColorMode } = useColorMode()
  const { t, i18n } = useTranslation()

  const isDarkTheme = colorMode === "dark"

  const linkSections: ISections = {
    applicationArea: {
      text: t("application-area"),
      ariaLabel: t("application-area-menu"),
      items: [
        {
          to: "/products/find-product/",
          text: t("all-products"),
        },
        {
          to: `/use-cases`,
          text: t("use-cases"),
        },
        {
          to: `/roadmap`,
          text: t("roadmap"),
        },
      ],
    },
    /* TODO:
    learn: {
      text: t("learn"),
      ariaLabel: t("learn-menu"),
      items: [
        {
          text: t("start-here"),
          items: [
            {
              to: `/learn`,
              text: t("learn"),
            },
            {
              to: `/what-is-sczh`,
              text: t("what-is-sczh"),
            },
          ],
        },
        {
          text: t("sczh-tech-basics"),
          items: [
            {
              to: `/apps`,
              text: t("applications-apps"),
            },
          ],
        },
        {
          text: t("sczh-tech-protocol"),
          items: [
            {
              to: `/apps`,
              text: t("applications-apps"),
            },
          ],
        },
      ],
    },
    standardSolutions: {
      text: t("standard-solutions"),
      ariaLabel: t("page-standard-solutions-aria-label"),
      items: [
        {
          to: `/standard-solutions`,
          text: t("get-started"),
          isPartiallyActive: false,
        },
        {
          to: `/standard-solutions/docs/`,
          text: t("documentation"),
        },
        {
          to: `/standard-solutions/tutorials/`,
          text: t("tutorials"),
        },
        {
          to: `/learning-tools`,
          text: t("learning-tools"),
        },
      ],
    }, */
    news: {
      text: t("news"),
      ariaLabel: t("news-menu"),
      items: [
        {
          to: `/news`,
          text: t("all-news"),
        }, {
          to: "https://blog.sczh.tech/",
          text: t("all-blogs"),
        },
      ],
    },
    about: {
      text: t("about"),
      ariaLabel: t("about"),
      items: [
        {
          text: t("about"),
          to: "/about",
        },
      ],
    },
  }

  const subNavLinks: Array<IItem> = [
    {
      text: t("home"),
      to: "/",
      isPartiallyActive: false,
    },
    {
      text: t("docs"),
      to: "/standard-solutions/docs",
    },
    {
      text: t("tutorials"),
      to: "/standard-solutions/tutorials",
    }
  ]

  let mobileLinkSections = cloneDeep(linkSections)
  const toggleMenu = (): void => {
    setIsMenuOpen((prev) => !prev)
    document.documentElement.style.overflowY = isMenuOpen ? "scroll" : "hidden"
  }

  const searchRef = useRef<HTMLButtonElement>(null)

  const toggleSearch = (): void => {
    searchRef.current?.click()
  }
  const shouldShowSubNav = path.includes("/standard-solutions/")
  const splitPath = path.split("/")
  const fromPageParameter =
    splitPath.length > 3 && splitPath[2] !== "languages"
      ? `?from=/${splitPath.slice(2).join("/")}`
      : ""

  const mobileNavProps = {
    isMenuOpen,
    isDarkTheme,
    toggleMenu,
    toggleTheme: toggleColorMode,
    toggleSearch,
    linkSections: mobileLinkSections,
    fromPageParameter,
  }

  return {
    toggleColorMode,
    t,
    i18n,
    isDarkTheme,
    subNavLinks,
    linkSections,
    searchRef,
    shouldShowSubNav,
    fromPageParameter,
    mobileNavProps,
  }
}
