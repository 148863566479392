import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Flex, List, Text, Box } from "@chakra-ui/react"

import NavDropdown from "./Dropdown"
import ButtonLink from "../ButtonLink"
import Translation from "../Translation"
import { getDirection } from "../../utils/translations"

import { Lang } from "../../utils/languages"
import { ISections } from "./types"

export interface IProps {
  path: string
  sections: ISections
}

const Menu: React.FC<IProps> = ({ path, sections }) => {
  const { language } = useI18next()
  const direction = getDirection(language as Lang)
  const shouldShowSubNav = path.includes("/standard-solutions/")

  const {
    applicationArea,
    // learn,
    ...restSections
  } = sections

  // const [start, basics, protocol] = learn.items

  return (
    <Flex as={List} alignItems="center" m={0} gap={{ base: 3, xl: 6 }}>
      <NavDropdown section={applicationArea} hasSubNav={shouldShowSubNav}>
        {applicationArea.items.map((item, index) => (
          <NavDropdown.Item
            key={index}
            isLast={index === applicationArea.items.length - 1}
          >
            <NavDropdown.Link to={item.to} isPartiallyActive={false}>
              {item.text}
            </NavDropdown.Link>
          </NavDropdown.Item>
        ))}
      </NavDropdown>

      {/* 
      <NavDropdown section={learn} hasSubNav={shouldShowSubNav}>
        <Flex flexDir={direction === "rtl" ? "row-reverse" : "row"}>
          <Flex flexDir="column" gap={4}>
            {[start, basics].map((section, index) => (
              <List m={0} key={index}>
                <NavDropdown.Title>{section.text}</NavDropdown.Title>
                {(section.items || []).map((item, index) => (
                  <NavDropdown.Item key={index}>
                    <NavDropdown.Link to={item.to} isPartiallyActive={false}>
                      {item.text}
                    </NavDropdown.Link>
                  </NavDropdown.Item>
                ))}
              </List>
            ))}
          </Flex>
          <div>
            <List m={0}>
              <NavDropdown.Title>{protocol.text}</NavDropdown.Title>
              {(protocol.items || []).map((item, index) => (
                <NavDropdown.Item
                  key={index}
                  isLast={index === (protocol.items || []).length - 1}
                >
                  <NavDropdown.Link to={item.to} isPartiallyActive={false}>
                    {item.text}
                  </NavDropdown.Link>
                </NavDropdown.Item>
              ))}
            </List>
          </div>
        </Flex>
      </NavDropdown>
      */}

      {Object.keys(restSections).map((sectionKey) => {
        const section = restSections[sectionKey]

        return (
          section.items.length === 1 ?
          <>
            {section.items.map((item) => (
              <ButtonLink
                to={item.to}
                variant="icon"
                px={{ base: 1, xl: 1.5 }}
                size="sm"
                fontSize="md"
              >
                <Text as="span" pl={2}>
                  <Box as="span" hideBelow="lg">
                    <Translation id="about" />
                  </Box>
                </Text>
              </ButtonLink>
            ))}
          </>
          :
          <NavDropdown
            key={sectionKey}
            section={section}
            hasSubNav={shouldShowSubNav}
          >
            {section.items.map((item, index) => (
              <NavDropdown.Item
                key={index}
                isLast={index === section.items.length - 1}
              >
                <NavDropdown.Link to={item.to} isPartiallyActive={false}>
                  {item.text}
                </NavDropdown.Link>
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        )
      })}
    </Flex>
  )
}

export default Menu
