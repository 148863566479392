import {
  Box,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  SimpleGrid,
  useToken,
} from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaGithub, FaZhihu, FaWeixin, FaTiktok, FaWeibo } from "react-icons/fa"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import { Lang } from "../utils/languages"
import { getLocaleTimestamp } from "../utils/time"
import { isLangRightToLeft, TranslationKey } from "../utils/translations"
import Link from "./Link"
import Translation from "./Translation"

const socialLinks = [
  // {
  //   icon: FaGithub,
  //   to: "https://github.com/imzqqq/sczh.tech",
  //   ariaLabel: "GitHub",
  // },
  {
    icon: FaZhihu,
    to: "https://zhihu.com/sczh-tech",
    ariaLabel: "Zhihu",
  }, {
    icon: FaWeixin,
    to: "https://weixin.com/channel/sczh-tech",
    ariaLabel: "WeChat",
  }, {
    icon: FaWeibo,
    to: "https://weibo.com/sczh-tech",
    ariaLabel: "Weibo",
  }, {
    icon: FaTiktok,
    to: "https://douyin.com/sczh-tech",
    ariaLabel: "DouYin",
  },
]

export interface LinkSection {
  title: TranslationKey
  links: Array<{
    to: string
    text: TranslationKey
    isPartiallyActive?: boolean
  }>
}

export interface IProps {}

const Footer: React.FC<IProps> = () => {
  const { language } = useI18next()
  const { t } = useTranslation()

  const isPageRightToLeft = isLangRightToLeft(language as Lang)

  const [medBp] = useToken("breakpoints", ["md"])

  const linkSections: Array<LinkSection> = [
    {
      title: t("application-area"),
      links: [
        {
          to: "/products/find-product/",
          text: t("all-products"),
        }, {
          to: `/use-cases`,
          text: t("use-cases"),
        }, {
          to: `/roadmap`,
          text: t("roadmap"),
        },
      ],
    },
    /* TODO: 
    {
      title: t("learn"),
      links: [
        {
          to: `/learn`,
          text: t("learn"),
        },
        {
          to: `/apps`,
          text: t("applications-apps"),
        },
        {
          to: `/what-is-sczh`,
          text: t("what-is-sczh"),
        },
      ],
    },
    {
      title: t("standard-solutions"),
      links: [
        {
          to: `/standard-solutions`,
          text: t("get-started"),
          isPartiallyActive: false,
        },
        {
          to: `/standard-solutions/docs/`,
          text: t("documentation"),
        },
        {
          to: `/standard-solutions/tutorials/`,
          text: t("tutorials"),
        },
        {
          to: `/learning-tools`,
          text: t("learning-tools"),
        },
      ],
    },*/
    {
      title: t("news"),
      links: [
        {
          to: `/news`,
          text: t("all-news"),
        }, {
          to: "https://blog.sczh.tech/",
          text: t("all-blogs"),
        },
      ],
    }, {
      title: t("about-sczh-tech"),
      links: [
        {
          to: "/about/",
          text: t("about-us"),
        }, {
          to: "/qualification/",
          text: t("qualification"),
        }, {
          to: "/open-jobs",
          text: t("jobs"),
        }, {
          to: "/languages/",
          text: t("language-support"),
        }, {
          to: "/privacy-policy/",
          text: t("privacy-policy"),
        }, {
          to: "/terms-of-use/",
          text: t("terms-of-use"),
        }, {
          to: "/cookie-policy/",
          text: t("cookie-policy"),
        }, {
          to: "mailto:sichuang_smart@126.com",
          text: t("contact"),
        },
      ],
    },
  ]

  const data = useStaticQuery(graphql`
    query {
      allSiteBuildMetadata {
        edges {
          node {
            buildTime
          }
        }
      }
    }
  `)

  return (
    <Box as="footer" p="1rem 2rem" borderTopWidth="1px">
      <Flex
        fontSize="sm"
        justify="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box color="text200">
          <Translation id="website-last-updated" />:{" "}
          {getLocaleTimestamp(
            language as Lang,
            data.allSiteBuildMetadata.edges[0].node.buildTime
          )}
        </Box>
        <Box my={4}>
          {socialLinks.map((link, idk) => {
            return (
              <Link
                key={idk}
                to={link.to}
                hideArrow
                color="secondary"
                aria-label={link.ariaLabel}
              >
                <Icon as={link.icon} fontSize="4xl" ml={4} />
              </Link>
            )
          })}
        </Box>
      </Flex>
  
      <SimpleGrid
        gap={4}
        justifyContent="space-between"
        gridTemplateColumns="repeat(6, auto)"
        sx={{
          "@media (max-width: 1300px)": {
            gridTemplateColumns: "repeat(3, auto)",
          },
          [`@media (max-width: ${medBp})`]: {
            gridTemplateColumns: "repeat(2, auto)",
          },
          "@media (max-width: 500px)": {
            gridTemplateColumns: "auto",
          },
        }}
      >
        {linkSections.map((section: LinkSection, idx) => (
          <Box key={idx}>
            <Heading as="h3" fontSize="sm" lineHeight="1.6" my="1.14em">
              <Translation id={section.title} />
            </Heading>
            <List fontSize="sm" lineHeight="1.6" fontWeight="400" m={0}>
              {section.links.map((link, linkIdx) => (
                <ListItem key={linkIdx} mb={4}>
                  <Link
                    to={link.to}
                    isPartiallyActive={false}
                    dir={isPageRightToLeft ? "auto" : "ltr"}
                    textDecor="none"  // Remove text underline
                    color="text200"
                    _hover={{
                      color: "primary",
                      svg: {
                        fill: "primary",
                      },
                    }}
                    sx={{
                      svg: {
                        fill: "text200",
                      },
                    }}
                  >
                    {link.text}
                  </Link>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default Footer
