import * as React from "react"
import { Icon } from "@chakra-ui/react"

export const SczhHomeIcon = () => (
  <Icon
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="32px"
    viewBox="0 0 236.000000 236.000000"
    preserveAspectRatio="xMidYMid meet"
  > 
    <g transform="translate(0.000000,236.000000) scale(0.100000,-0.100000)"
    fill="#007cba" stroke="none">
      <path d="M877 2194 c-3 -3 -25 -10 -48 -15 -64 -15 -202 -82 -277 -136 -172
      -122 -310 -291 -383 -467 -44 -107 -41 -111 61 -76 144 49 251 147 355 329 97
      169 185 284 251 328 48 32 61 43 51 43 -2 0 -6 -3 -10 -6z"/>
      <path d="M1190 2164 c-77 -15 -170 -52 -170 -68 0 -9 117 -97 298 -223 l46
      -33 36 21 c77 47 210 77 210 48 0 -28 -37 -83 -76 -116 -24 -19 -44 -39 -44
      -44 0 -7 112 -89 317 -232 33 -23 65 -37 85 -38 24 0 16 -4 -31 -14 -35 -8
      -75 -22 -90 -31 -24 -16 -25 -16 -12 -1 7 9 9 17 4 17 -5 0 -18 8 -29 18 -10
      9 -41 32 -69 49 -27 18 -55 36 -60 41 -6 5 -47 33 -90 63 -44 30 -85 60 -91
      68 -8 11 -17 6 -43 -25 -43 -53 -116 -204 -139 -289 -17 -64 -22 -71 -53 -82
      -19 -7 -49 -12 -67 -12 -27 -1 -32 2 -28 17 2 9 12 49 22 87 28 117 87 258
      136 328 11 15 16 27 12 27 -4 0 1 7 12 16 10 8 12 12 4 8 -12 -6 -13 -5 -3 7
      14 17 18 13 -182 159 -55 40 -114 83 -130 95 l-31 23 -39 -21 c-86 -44 -192
      -179 -286 -366 -37 -73 -48 -105 -40 -110 23 -14 -121 -323 -198 -424 -62 -82
      -135 -109 -190 -71 -22 15 -23 21 -16 77 7 70 33 143 70 198 36 55 31 59 -10
      8 -48 -60 -82 -133 -96 -210 -10 -53 -9 -82 5 -167 38 -241 151 -429 304 -504
      65 -32 188 -32 248 -1 66 35 161 121 210 189 81 113 144 298 175 511 12 80 13
      82 43 88 17 4 39 9 49 11 21 7 20 -4 -29 -253 -32 -161 -42 -278 -28 -331 5
      -19 12 -22 30 -18 17 5 24 2 24 -7 0 -40 74 -159 134 -215 80 -74 139 -97 246
      -96 94 2 159 26 261 99 68 48 208 199 196 212 -2 2 -24 -1 -48 -6 -65 -15
      -159 -13 -212 5 -112 38 -176 137 -185 285 -9 156 58 351 148 433 49 44 134
      71 195 61 60 -8 147 -55 162 -85 7 -14 13 -56 13 -95 0 -117 -37 -221 -111
      -316 -56 -71 -29 -61 31 12 93 112 136 234 127 362 l-4 68 21 -18 c11 -10 24
      -22 28 -28 10 -13 1 39 -23 139 -64 266 -270 437 -468 390 -76 -18 -76 -18
      -47 17 14 17 29 41 32 55 4 16 13 24 28 24 25 0 121 -43 130 -59 4 -6 12 -11
      18 -11 18 0 -1 52 -38 107 -44 64 -118 116 -215 150 -65 23 -92 27 -219 29
      -80 1 -156 0 -170 -2z"/>
      <path d="M875 486 c-35 -47 -47 -92 -35 -136 24 -87 82 -135 201 -166 86 -22
      283 -14 384 15 77 23 216 89 255 122 17 14 8 11 -30 -9 -211 -116 -492 -143
      -655 -62 -66 32 -100 73 -117 138 -8 33 -6 48 10 86 12 25 19 46 17 46 -2 0
      -16 -15 -30 -34z"/>
    </g>
  </Icon>
)
